.energySourceInfoContainer {
  border-radius: 8px;
  border: 1px solid #e7e7e7;
}

.exportValueContainer {
  border-radius: 8px;
  border: 1px solid #eaecf3;
  background: #fff;
}
