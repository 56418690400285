.container {
  border-radius: 8px;
  letter-spacing: 1px;

  .title {
    color: #69768b;
    font-size: 10px;
    font-weight: 400;
  }

  .units {
    font-weight: 600;
    font-size: 16px;
    color: #383941;
  }
}
