.chartFilter {
  border-radius: 12px;
  border: 1px solid #eaecf3;
  background: var(--white, #fff);
  box-shadow: -4px 10px 22px 0px rgba(112, 109, 174, 0.26);
  position: relative;
  cursor: pointer;
}

.filterDot {
  width: 8px;
  height: 8px;
  background-color: #d84560;
  border-radius: 50%;
  position: absolute;
}
