.energySourceInfoContainer {
  border-radius: 8px;
  border: 1px solid #eaecf3;
  box-shadow: 0px 0px 12.8px 0px rgba(38, 51, 77, 0.07);
}

.whiteContainer {
  background-color: #fff;
}

.greenContainer {
  background-color: #2dec9f !important;
  color: #ffffff !important;
}

.blueContainer {
  background-color: #2eb5ed !important;
  color: #ffffff !important;
}
