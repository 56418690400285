.infoContainer {
  border-radius: 14.095px;
  background-color: #fff;
  box-shadow: 0px 60px 200px rgba(75, 75, 78, 0.2);
}

.powerIndicator {
  border: 4.5px solid;
  height: 42.286px;
  border-radius: 5.286px;
}

.powerIndicatorSecondType {
  border: 3px solid;
  height: 30px;
  border-radius: 5.286px;
}

.blueIndicator {
  color: #2eb6ee;
}

.greenIndicator {
  color: #2dec9f;
}
.yellowIndicator {
  color: #a19b12;
}

.grayIndicator {
  color: #c9c9cb;
}
